/** @jsxImportSource @emotion/react */

import { CacheProvider, EmotionCache, Global, css } from "@emotion/react";
import { Provider as MabiProvider, PreflightProvider } from "@mabi-ui/react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { appWithTranslation } from "next-i18next";
import "../public/style.css";
import "../styles/global.css";

import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import queryString from "querystring";
import { useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import TagManager from "react-gtm-module";

import TokenReader from "../components/TokenReader";
import SEO from "../next-seo.config";
import { ModalNavigatorProvider } from "../utils/ModalNavigator/ModalNavigator";

import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import moment from "moment";
import "moment/locale/it";
import { NextSeo } from "next-seo";
import { SSRProvider } from "react-aria";
import ErrorBoundary from "../components/ErrorBoundary";
import HistoryHandler from "../components/HistoryHandler";
import MLDialogProvider from "../components/MLDialogProvider";
import { queryClient } from "../utils/ReactQuery";
import createEmotionCache from "../utils/createEmotionCache";
import { V5ThemeProvider } from "../utils/v5Theme/v5Theme";

moment.locale("it");

const { publicRuntimeConfig } = getConfig();
const { HOST } = publicRuntimeConfig;

const tagManagerArgs = {
	gtmId: "GTM-PNMNQCZ",
};

const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
	emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
	useEffect(() => {
		const search = window.location.search.replace("?", "");
		const parsedSearch = queryString.parse(search);

		const gclid = parsedSearch?.gclid as string;

		if (gclid) {
			localStorage.setItem("gclid", gclid);
		}

		if (HOST == "https://app.nowr.in/" || HOST == "https://nowr.mabiloft.com/") {
			TagManager.initialize(tagManagerArgs);
		}
	}, []);
	const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
	return (
		<CacheProvider value={emotionCache}>
			<Head>
				<title>NOWR</title>
				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
				<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" rel="stylesheet" />
				<link href="https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css" rel="stylesheet" crossOrigin="" />

				<script
					src="https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js"
					crossOrigin=""
					async
					data-initial-token="eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJpc3MiOiJaRFJQMjZXMzg1IiwiaWF0IjoxNzE1MDkzNTA1LCJkb21haW5zIjpbIioubWFiaWxvZnQuY29tIl19.Y0kRoIjJ-Zs4epDmS0uBkRONN9-us8l28pIeGTUYCY7NQhDHSWvfyzZ8yKVgwuv8zx4dVJNtybPfV4rcUS37vQ"
				></script>
			</Head>
			<Global
				styles={css`
					@font-face {
						font-family: "CeraPro";
						src: url("/fonts/CeraPro-Bold.otf");
						font-weight: bold;
						font-display: auto;
						font-style: normal;
					}

					@font-face {
						font-family: "CeraPro";
						src: url("/fonts/CeraPro-Regular.otf");
						font-weight: normal;
						font-display: auto;
						font-style: normal;
					}

					@font-face {
						font-family: "Neon";
						src: url("/fonts/NeonTubes.otf");
						font-display: auto;
					}

					body {
						background-color: white;
					}

					* {
						font-family: "CeraPro";
					}
				`}
			/>
			<NextSeo {...SEO} />
			<ErrorBoundary>
				<HistoryHandler />
				<QueryClientProvider client={queryClient}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<MabiProvider>
							<PreflightProvider>
								<V5ThemeProvider>
									<MLDialogProvider>
										<ModalNavigatorProvider>
											<SSRProvider>
												<TokenReader />
												{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
												<CssBaseline />
												{/* <Layout> */}
												<Component {...pageProps} />
												<Analytics />
												{/* </Layout> */}
											</SSRProvider>
										</ModalNavigatorProvider>
									</MLDialogProvider>
								</V5ThemeProvider>
							</PreflightProvider>
						</MabiProvider>
					</LocalizationProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ErrorBoundary>
		</CacheProvider>
	);
}

export default appWithTranslation(MyApp);
