import Image from "next/image";
import React, { ReactNode } from "react";

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false };
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI

		return { hasError: true };
	}
	componentDidCatch(error, errorInfo) {
		// You can use your own error logging service here
		console.log({ error, errorInfo });
	}
	render() {
		if (this.state.hasError) {
			return (
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 16px)",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "#ffffff",
						borderRadius: "48px 48px 0px 0px",
					}}
				>
					<Image src="/images/ErrorBoundaryBaloon.png" alt="Error illustration" width={143} height={147} />
					<h2
						style={{
							fontFamily: '""CeraPro""',
							fontWeight: 700,
							fontSize: 20,
							letterSpacing: "0.15px",
							margin: 0,
							marginTop: 24,
						}}
					>
						Oh no! Qualcosa è andato storto.
					</h2>
					<p
						style={{
							fontFamily: '""CeraPro""',
							fontWeight: 400,
							fontSize: 16,
							letterSpacing: "0.5px",
							margin: 0,
							marginTop: 24,
						}}
					>
						Non è stato possibile concludere l'azione richiesta.
					</p>
					<a
						href="/"
						style={{
							fontFamily: '"CeraPro"',
							fontWeight: 400,
							fontSize: 16,
							letterSpacing: "0.5px",
							margin: 0,
							backgroundColor: "#00FDC7",
							color: "#000000",
							textDecoration: "none",
							padding: "8px 12px",
							borderRadius: "10px",
							marginTop: 40,
							lineHeight: "24px",
							boxShadow: "unset !important",
							border: "unset",
							cursor: "pointer",
						}}
					>
						Vai alla home
					</a>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
