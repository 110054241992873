import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

const storage = createJSONStorage<string[]>(() => sessionStorage);
const internalHistoryAtom = atomWithStorage("historyUrls", [] as string[], storage);

export const historyUrlAtom = atom(
	(get) => {
		const history = get(internalHistoryAtom) || [];

		const last = history.length > 0 ? history[history.length - 1] : undefined;

		return { last: last, history: history };
	},
	(_get, set, url: string) => {
		const currentHistory = _get(internalHistoryAtom);
		currentHistory.push(url);

		if (currentHistory.length > 10) {
			currentHistory.shift();
		}

		set(internalHistoryAtom, [...currentHistory]);
	}
);
