import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { historyUrlAtom } from "../../stores/historyUrlAtom";

const HistoryHandler: React.FC = ({}) => {
	const router = useRouter();

	const pushUrl = useSetAtom(historyUrlAtom);

	useEffect(() => {
		function handleUrl(url: string) {
			pushUrl(url);
		}

		router.events.on("routeChangeComplete", handleUrl);

		return () => {
			router.events.off("routeChangeComplete", handleUrl);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
export default HistoryHandler;
