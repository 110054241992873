const title = "NOWR Italia | Scopri i migliori eventi nella tua cittá | Scarica l'app";
const description =
	"NOWR ti aiuta a scoprire ciò che c'è da fare attorno a te.Troverai eventi sociali, di musica, arte, sport e tutto quello che ti può portare fuori di casa";
const url = "https://app.nowr.in/";

import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { HOST } = publicRuntimeConfig;

// console.log("HOST", HOST != "https://app.nowr.in/");

const SEO = {
	noindex: HOST != "https://app.nowr.in/",
	nofollow: HOST != "https://app.nowr.in/",
	title,
	description,
	canonical: url,
	openGraph: {
		url: url,
		images: [
			{
				url: url + "images/banner.jpg",
				width: 1024,
				height: 500,
				alt: "NOWR Italia | Scopri i migliori eventi nella tua cittá | Scarica l'app",
			},
		],
		site_name: title,
		description,
		title,
		type: "website",
		locale: "it_IT",
	},
	twitter: {
		handle: "@handle",
		site: "@site",
		cardType: "summary_large_image",
	},
};

export default SEO;
