import { useSetAtom } from "jotai";
import { useAtomDevtools } from "jotai/devtools";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { tokenAtom } from "../stores/tokenAtom";

const TokenReader = () => {
	const setTokenAtom = useSetAtom(tokenAtom);

	useAtomDevtools(tokenAtom);

	useEffect(() => {
		setTokenAtom(Cookies.get("nowr_token") || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
export default TokenReader;
