export function CheckboxIcon(props) {
	return (
		<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width={20} height={20} rx={4} fill="#fff" fillOpacity={0.12} />
			<rect x={1} y={1} width={18} height={18} rx={3} stroke="#0C1917" strokeOpacity={0.38} strokeWidth={2} />
		</svg>
	);
}

export function CheckboxIconChecked(props) {
	return (
		<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width={20} height={20} rx={4} fill="#00FDC7" />
			<g filter="url(#prefix_prev_checkbox)">
				<mask
					id="prefix__checkbox"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={5}
					y={6}
					width={10}
					height={8}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M13.468 6.931a.526.526 0 01.75 0l.207-.205-.207.205c.209.211.209.555 0 .766l-5.285 5.336a.526.526 0 01-.75 0l-2.402-2.425a.546.546 0 010-.766.526.526 0 01.75 0l1.82 1.838a.292.292 0 00.415 0l4.702-4.749-.206-.204.206.204z"
						fill="#fff"
					/>
				</mask>
				<g mask="url(#prefix__checkbox)">
					<path fill="#0C1917" d="M3 2.982h14v14H3z" />
				</g>
			</g>
			<defs>
				<filter
					id="prefix_prev_checkbox"
					x={1}
					y={2}
					width={18}
					height={18}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_223_35" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_223_35" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}

export function CheckboxIconIndeterminate(props) {
	return (
		<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width={20} height={20} rx={4} fill="#00FDC7" />
			<g filter="url(#prefix__filter0_d_223_39)">
				<mask
					id="prefix__check_indeterminated"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={5}
					y={9}
					width={10}
					height={2}
				>
					<path
						d="M14.521 10a.438.438 0 01-.438.438H5.918a.438.438 0 010-.876h8.166c.242 0 .438.196.438.438z"
						fill="#fff"
						stroke="#fff"
						strokeWidth={0.5}
						strokeLinecap="round"
					/>
				</mask>
				<g mask="url(#prefix__check_indeterminated)">
					<path fill="#0C1917" d="M3 3h14v14H3z" />
				</g>
			</g>
			<defs>
				<filter
					id="prefix__filter0_d_223_39"
					x={1}
					y={2}
					width={18}
					height={18}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_223_39" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_223_39" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}
