// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV;

Sentry.init({
	dsn: SENTRY_DSN || "https://e82a9ef8f6b4477292f8ad05ae0b1820@o1302078.ingest.sentry.io/4504061856120832",
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: ENV === "prod" ? 1.0 : 0.2,

	enabled: ENV !== "local",
	environment: ENV,
});
