/** @jsxImportSource @emotion/react */

import type { CSSObject } from "@emotion/react";
import { Color, colors, Theme, useTheme } from "@mui/material";
import type { Palette, PaletteColor } from "@mui/material/styles";

import React from "react";

import { NowrIconsMap } from "./Nowr.constants";

type ThemeColor = `${keyof Palette}.${keyof PaletteColor}`;
type StandardColor = `${keyof typeof colors}` | `${keyof typeof colors}.${keyof Color}`;

export type NowrIconNames = keyof typeof NowrIconsMap;

type NowrThemeColor = ThemeColor | StandardColor;
type NowrColor =
	| NowrThemeColor
	| `#${string}`
	| keyof typeof colors
	| "black"
	| "white"
	| `rgb(${string})`
	| `rgba(${string})`
	| string
	| "inherit";

interface NowrIconProps {
	color?: NowrColor;
	size?: 4 | 8 | 12 | 16 | 24 | 28 | 32 | 36 | 40 | 48 | 52 | 56 | 60 | 64 | number;
	name?: NowrIconNames;
	iconCss?: CSSObject;
}

interface NowrIconProps {
	twoColor?: boolean;
	foreground?: {
		name: NowrIconNames;
		color: NowrColor;
	};
	background?: {
		name: NowrIconNames;
		color: NowrColor;
	};
	size?: 4 | 8 | 12 | 16 | 24 | 28 | 32 | 36 | 40 | 48 | 52 | 56 | 60 | 64 | number;
	iconCss?: CSSObject;
	className?: string;
}

const NowrIcon = React.forwardRef<any, NowrIconProps>((props, ref) => {
	const { color, size, name, twoColor, foreground, background, iconCss, ...otherProps } = props;
	const theme = useTheme();

	if (!props.twoColor) {
		const customColor = getCustomColorFromTheme(color || "text", theme);
		if (!name) throw new Error("missing name property");

		return (
			<i
				ref={ref}
				css={{
					color: customColor,
					// paddingTop: 1,
					fontSize: `${size ?? 24}px !important`,
					height: size ?? 24,
					width: size ?? 24,
					...props.iconCss,
				}}
				dangerouslySetInnerHTML={{
					__html: NowrIconsMap?.[name || "super_success"],
				}}
				{...otherProps}
			/>
		);
	} else {
		if (!foreground || !background) throw new Error("missing foreground or background property");

		const foregroundColor = getCustomColorFromTheme(foreground.color, theme);
		const backgroundColor = getCustomColorFromTheme(background.color, theme);

		return (
			<div
				ref={ref}
				css={{
					position: "relative",
					height: size ?? 24,
					width: size ?? 24,
					display: "inline-block",
					...props.iconCss,
				}}
				{...otherProps}
			>
				<i
					css={{
						position: "absolute",
						color: foregroundColor,
						fontSize: size ?? 24,
					}}
					dangerouslySetInnerHTML={{
						__html: NowrIconsMap?.[foreground?.name || "super_success"],
					}}
				/>
				<i
					css={{
						position: "absolute",
						color: backgroundColor,
						fontSize: size ?? 24,
					}}
					dangerouslySetInnerHTML={{
						__html: NowrIconsMap?.[background?.name || "super_success"],
					}}
				/>
			</div>
		);
	}
});

export default NowrIcon;

function getCustomColorFromTheme(color: string, theme: Theme) {
	const splittedColor = color?.split(".");
	const typeColor = (splittedColor?.[0] || "primary") as any as PaletteColor;
	const shadeColor = splittedColor?.[1] || "main";

	let customColor = "black";

	if (Number(shadeColor.replace("A", ""))) {
		//@ts-ignore
		customColor = Colors[typeColor]?.[shadeColor] || color || "black";
	} else {
		//@ts-ignore
		customColor = theme.palette?.[typeColor]?.[shadeColor] || color;
	}
	return customColor;
}
