/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css";

import { Box } from "@mui/material";
import dynamic from "next/dynamic";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";

import React from "react";
import NowrIcon from "./icons/NowrIcon";

const __MLDialog = dynamic(() => import("./MLDialog").then((mod) => mod.__MLDialog), { ssr: false });

class MLDialogProvider extends React.Component<SnackbarProviderProps> {
	render() {
		return (
			<SnackbarProvider
				{...this.props}
				classes={{
					variantSuccess: css`
						background: #00fdc7 !important;
						padding: 4px 8px !important;
						border-radius: 8px !important;
						box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
						font-family: "CeraPro";
						font-size: 14px;
						line-height: 18px;
						color: #0c1917 !important;
						& .SnackbarItem-action {
							margin-right: unset !important;
						}
					`,
					variantError: css`
						background: radial-gradient(100% 400% at 100% 100%, #ff44a8 0%, #f24c55 100%);
						padding: 4px 8px !important;
						border-radius: 8px !important;
						box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
						font-family: "CeraPro";
						font-size: 14px;
						line-height: 18px;
						color: #f8fffd;
						& .SnackbarItem-action {
							margin-right: unset !important;
						}
					`,
					variantInfo: css`
						background-color: rgba(67, 169, 191, 1) !important;
						padding: 4px 8px !important;
						border-radius: 8px !important;
						box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
						font-family: "CeraPro";
						font-size: 14px;
						line-height: 18px;
						color: #f8fffd;
						& .SnackbarItem-action {
							margin-right: unset !important;
						}
					`,
					variantWarning: css`
						background-color: rgba(255, 149, 0, 1) !important;
						padding: 4px 8px !important;
						border-radius: 8px !important;
						box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.24);
						font-family: "CeraPro";
						font-size: 14px;
						line-height: 18px;
						color: #f8fffd;
						& .SnackbarItem-action {
							margin-right: unset !important;
						}
					`,
				}}
				iconVariant={{
					success: (
						<Box p={"6px"} sx={{ backgroundColor: "rgba(0, 0, 0, 0.10)", borderRadius: 2, marginRight: 2 }}>
							<NowrIcon size={30} color="black" name={"success"} />
						</Box>
					),
					error: (
						<Box p={"6px"} sx={{ backgroundColor: "rgba(255, 255, 255, 0.38)", borderRadius: 2, marginRight: 2 }}>
							<NowrIcon size={30} color="white" name={"error_outline"} />
						</Box>
					),
					warning: (
						<Box p={"6px"} sx={{ backgroundColor: "rgba(255, 255, 255, 0.38)", borderRadius: 2, marginRight: 2 }}>
							<NowrIcon size={30} color="white" name={"alert_outline"} />
						</Box>
					),
					info: (
						<Box p={"6px"} sx={{ backgroundColor: "rgba(255, 255, 255, 0.38)", borderRadius: 2, marginRight: 2 }}>
							<NowrIcon size={30} color="white" name={"info_outline"} />
						</Box>
					),
				}}
			>
				<__MLDialog />
				{this.props.children}
			</SnackbarProvider>
		);
	}
}

export default MLDialogProvider;
