import {
	alertClasses,
	alpha,
	chipClasses,
	createTheme,
	inputBaseClasses,
	inputClasses,
	inputLabelClasses,
	listItemIconClasses,
	listItemTextClasses,
	paperClasses,
	selectClasses,
	switchClasses,
	tabsClasses,
	ThemeProvider,
	touchRippleClasses,
} from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { CheckboxIcon, CheckboxIconChecked, CheckboxIconIndeterminate } from "./components/CheckBoxIcons";
import { pxToRem } from "./utils/pxToRem";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		link: React.CSSProperties;
		label: React.CSSProperties;
	}
	interface TypographyVariantsOptions {
		link: React.CSSProperties;
		label?: React.CSSProperties;
	}
	interface PaletteColor {
		a6?: string;
		a12?: string;
		a38?: string;
		a54?: string;
		a60?: string;
		a74?: string;
		a87?: string;
	}
	interface SimplePaletteColorOptions {
		a6?: string;
		a12?: string;
		a38?: string;
		a54?: string;
		a60?: string;
		a74?: string;
		a87?: string;
	}
	interface Palette {
		black: Palette["primary"];
		blue: Palette["primary"];
		loading: Palette["primary"];
		validation: Palette["primary"];
	}
	interface PaletteOptions {
		black: PaletteOptions["primary"];
		blue: PaletteOptions["primary"];
		loading: PaletteOptions["primary"];
		validation: PaletteOptions["primary"];
		default: PaletteOptions["primary"];
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		blue: true;
		black: true;
	}
}

declare module "@mui/material/TextField" {
	interface TextFieldPropsColorOverrides {
		loading: true;
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		link: true;
		label: true;
	}
}

const primaryColor = "#00FDC7";
const secondaryColor = "#0C1917";
const errorColor = "#BF0000";
const successColor = "#07B338";
const warningColor = "#FF9500";
const loadingColor = "#0A84FF";

export const v5Theme = createTheme({
	spacing: 4,
	typography: {
		fontFamily: ["CeraPro", "Roboto", "sans-serif"].join(","),
		fontWeightBold: 700,
		fontWeightRegular: 400,
		allVariants: {
			color: secondaryColor,
		},
		h1: {
			fontSize: pxToRem(96),
			fontWeight: "bold",
			letterSpacing: "-1.5px",
			lineHeight: pxToRem(96),
		},
		h2: {
			fontSize: pxToRem(60),
			fontWeight: "bold",
			letterSpacing: "-0.5px",
			lineHeight: pxToRem(60),
		},
		h3: {
			fontSize: pxToRem(48),
			fontWeight: "bold",
			lineHeight: pxToRem(48),
		},
		h4: {
			fontSize: pxToRem(34),
			fontWeight: "bold",
			letterSpacing: "0.25px",
			lineHeight: pxToRem(34),
		},
		h5: {
			fontSize: pxToRem(24),
			fontWeight: "bold",
			lineHeight: pxToRem(24),
		},
		h6: {
			fontSize: pxToRem(20),
			fontWeight: "bold",
			letterSpacing: "0.15px",
			lineHeight: pxToRem(20),
		},
		subtitle1: {
			fontSize: pxToRem(16),
			fontWeight: "bold",
			letterSpacing: "0.15px",
			lineHeight: pxToRem(16),
		},
		subtitle2: {
			fontSize: pxToRem(14),
			fontWeight: "bold",
			letterSpacing: "0.1px",
			lineHeight: pxToRem(14),
		},
		body1: {
			fontSize: pxToRem(16),
			fontWeight: 400,
			letterSpacing: "0.5px",
			lineHeight: pxToRem(22.4),
		},
		body2: {
			fontSize: pxToRem(14),
			fontWeight: 400,
			letterSpacing: "0.25px",
			lineHeight: pxToRem(18),
		},
		button: {
			fontSize: pxToRem(16),
			fontWeight: "bold",
			letterSpacing: "1.25px",
			lineHeight: pxToRem(16),
		},
		caption: {
			fontSize: pxToRem(12),
			fontWeight: 400,
			letterSpacing: "0.4px",
			lineHeight: pxToRem(16.8),
		},
		overline: {
			fontSize: pxToRem(10),
			fontWeight: "bold",
			letterSpacing: "1.5px",
			lineHeight: pxToRem(10),
		},
		link: {
			fontSize: pxToRem(14),
			fontWeight: 400,
			letterSpacing: "0.25px",
			lineHeight: pxToRem(14),
			textDecoration: "underline",
			color: "#4B4DED",
		},
		label: {
			fontSize: pxToRem(10),
			fontWeight: 400,
			letterSpacing: "0px",
			lineHeight: pxToRem(10),
		},
	},
	palette: {
		primary: {
			main: primaryColor,
			a6: alpha(primaryColor, 0.06),
			a12: alpha(primaryColor, 0.12),
			a38: alpha(primaryColor, 0.38),
			a54: alpha(primaryColor, 0.54),
			a60: alpha(primaryColor, 0.6),
			a74: alpha(primaryColor, 0.74),
			a87: alpha(primaryColor, 0.87),
		},
		secondary: {
			main: secondaryColor,
			a6: alpha(secondaryColor, 0.06),
			a12: alpha(secondaryColor, 0.12),
			a38: alpha(secondaryColor, 0.38),
			a54: alpha(secondaryColor, 0.54),
			a60: alpha(secondaryColor, 0.6),
			a74: alpha(secondaryColor, 0.74),
			a87: alpha(secondaryColor, 0.87),
		},
		error: {
			main: errorColor,
			a6: alpha(errorColor, 0.06),
			a12: alpha(errorColor, 0.12),
			a38: alpha(errorColor, 0.38),
			a54: alpha(errorColor, 0.54),
			a60: alpha(errorColor, 0.6),
			a74: alpha(errorColor, 0.74),
			a87: alpha(errorColor, 0.87),
		},
		success: {
			main: successColor,
			a6: alpha(successColor, 0.06),
			a12: alpha(successColor, 0.12),
			a38: alpha(successColor, 0.38),
			a54: alpha(successColor, 0.54),
			a60: alpha(successColor, 0.6),
			a74: alpha(successColor, 0.74),
			a87: alpha(successColor, 0.87),
		},
		warning: {
			main: warningColor,
			a6: alpha(warningColor, 0.06),
			a12: alpha(warningColor, 0.12),
			a38: alpha(warningColor, 0.38),
			a54: alpha(warningColor, 0.54),
			a60: alpha(warningColor, 0.6),
			a74: alpha(warningColor, 0.74),
			a87: alpha(warningColor, 0.87),
		},
		loading: {
			main: loadingColor,
			a6: alpha(loadingColor, 0.06),
			a12: alpha(loadingColor, 0.12),
			a38: alpha(loadingColor, 0.38),
			a54: alpha(loadingColor, 0.54),
			a60: alpha(loadingColor, 0.6),
			a74: alpha(loadingColor, 0.74),
			a87: alpha(loadingColor, 0.87),
			contrastText: "#ffffff",
		},
		black: {
			main: secondaryColor,
			a6: alpha(secondaryColor, 0.06),
			a12: alpha(secondaryColor, 0.12),
			a38: alpha(secondaryColor, 0.38),
			a54: alpha(secondaryColor, 0.54),
			a60: alpha(secondaryColor, 0.6),
			a74: alpha(secondaryColor, 0.74),
			a87: alpha(secondaryColor, 0.87),
			contrastText: "#ffffff",
		},
		blue: {
			main: "#3689B5",
		},
		default: {
			main: primaryColor,
			a6: alpha(primaryColor, 0.06),
			a12: alpha(primaryColor, 0.12),
			a38: alpha(primaryColor, 0.38),
			a54: alpha(primaryColor, 0.54),
			a60: alpha(primaryColor, 0.6),
			a74: alpha(primaryColor, 0.74),
			a87: alpha(primaryColor, 0.87),
		},
		background: {
			default: "#F8FFFD",
			paper: "#FFFFFF",
		},
		validation: {
			main: "#F9497E",
			light: "#FF44A8",
			dark: "#F24C55",
			contrastText: "#ffffff",
		},
	},
	components: {
		MuiTable: {
			styleOverrides: {
				root: {
					borderCollapse: "separate",
					borderSpacing: "0px 15px",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					"&:hover": {
						backgroundColor: "#F8FFFD",
						cursor: "pointer",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: 20,
					borderBottom: "none",
				},
				head: {
					backgroundColor: "#F8FFFD !important",
					fontSize: 16,
					fontWeight: 700,
					letterSpacing: "0.1px",
					color: "black",
				},
			},
		},

		MuiListItemButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: 8,
					borderRadius: 16,
					color: theme.palette.black.a74,
					"&:hover": {
						backgroundColor: "rgba(224, 255, 248, 1)",
					},
					[`& .${touchRippleClasses.root}`]: {
						color: theme.palette.black.a6,
					},
					[`& .${listItemIconClasses.root}`]: {
						minWidth: 0,
						padding: 4,
						borderRadius: 12,
						color: theme.palette.black.a87,
					},
					[`& .${listItemTextClasses.root}`]: {
						marginLeft: 8,
					},
					"&.Mui-selected": {
						fontWeight: "bold",
						color: theme.palette.black.a87,
						backgroundColor: "transparent",
						[`& .${listItemIconClasses.root}`]: {
							backgroundColor: theme.palette.primary.main,
						},
						[`& .${listItemTextClasses.root}`]: {
							[`& .${listItemTextClasses.primary}`]: {
								fontWeight: "bold",
							},
						},
					},
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					boxShadow: "unset",
					textTransform: "unset",
					lineHeight: pxToRem(24),
					minWidth: 0,
					boxSizing: "border-box",
					"&.Mui-disabled": {
						color: `${theme.palette.black.main}`,
						opacity: 0.38,
					},
				}),
				sizeLarge: ({ theme }) => ({
					padding: 16,
					fontSize: theme.typography.button.fontSize,
					fontWeight: theme.typography.button.fontWeight,
					letterSpacing: theme.typography.button.letterSpacing,
					textTransform: "uppercase",
					borderRadius: 12,
				}),
				sizeMedium: ({ theme }) => ({
					padding: "8px 12px",
					fontSize: theme.typography.body2.fontSize,
					fontWeight: theme.typography.body2.fontWeight,
					letterSpacing: theme.typography.body2.letterSpacing,
					borderRadius: 10,
				}),
				sizeSmall: ({ theme }) => ({
					padding: "4px 8px",
					borderRadius: 4,
					fontSize: theme.typography.caption.fontSize,
					fontWeight: theme.typography.caption.fontWeight,
					letterSpacing: theme.typography.caption.letterSpacing,
				}),
				contained: ({ ownerState, theme }) => ({
					// border: "1px solid rgba(0, 0, 0, 0)",
					backgroundColor: `${theme.palette[ownerState.color ?? "primary"].main}`,
					color: `${theme.palette[ownerState.color ?? "primary"].contrastText}`,
					"&:hover": {
						backgroundColor: `${theme.palette[ownerState.color ?? "primary"].main} !important`,
						boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
					},
					"&.Mui-disabled": {
						backgroundColor: theme.palette.black.a12,
					},
				}),
				containedSizeLarge: {
					"&:hover": {
						boxShadow: "0px 3px 1px rgba(0, 0, 0, 0.12)",
					},
				},
				outlined: ({ ownerState, theme }) => ({
					backgroundColor: "transparent",
					color: `${theme.palette[ownerState.color ?? "primary"].main}`,
					borderColor: `${theme.palette[ownerState.color ?? "primary"].main}`,
					boxSizing: "border-box",
					"&:hover": {
						backgroundColor: `${theme.palette[ownerState.color ?? "primary"].a12} !important`,
					},
				}),
				outlinedSizeLarge: {
					padding: 14,
				},
				outlinedSizeMedium: {
					padding: "6px 12px",
				},
				outlinedSizeSmall: {
					padding: "2px 8px",
				},
				text: ({ ownerState, theme }) => ({
					backgroundColor: "transparent",
					color: `${theme.palette[ownerState.color ?? "primary"].main}`,
					"&:hover": {
						backgroundColor: `${theme.palette[ownerState.color ?? "primary"].a12} !important`,
					},
				}),
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.palette.black.a12,
					borderRadius: 12,
					padding: 2,
					minHeight: 0,
					[`& .${tabsClasses.indicator}`]: {
						display: "none",
					},
					[`& .${tabsClasses.flexContainer}`]: {
						gap: 4,
					},
				}),
			},
		},
		MuiTab: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					minHeight: 0,
					borderRadius: 10,
					color: theme.palette.black.a54,
					transition: theme.transitions.create(["background-color", "color"]),
					textTransform: "unset",
					backgroundColor: "transparent",
					fontSize: theme.typography.caption.fontSize,
					fontWeight: 400,
					letterSpacing: theme.typography.caption.letterSpacing,
					lineHeight: pxToRem(24),
					padding: "4px 8px",
					"&:hover :not(.Mui-selected)": {
						backgroundColor: alpha(theme.palette[ownerState.color ?? "primary"].main, 0.06),
					},
					"&.Mui-selected": {
						backgroundColor: theme.palette[ownerState.color ?? "primary"].main,
						color: theme.palette[ownerState.color ?? "primary"].contrastText,
						textShadow: `-0.40px 0 ${theme.palette[ownerState.color ?? "primary"].contrastText}, 0.40px 0 ${
							theme.palette[ownerState.color ?? "primary"].contrastText
						}`,
						"&.Mui-disabled": {
							backgroundColor: theme.palette.black.a12,
							opacity: 0.38,
						},
					},
				}),
			},
		},
		MuiFormControlLabel: {
			defaultProps: {
				componentsProps: {
					typography: {
						variant: "subtitle2",
					},
				},
			},
			styleOverrides: {
				root: {
					gap: 10,
				},
			},
		},
		MuiSwitch: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					width: 36,
					height: 20,
					padding: 0,
				},
				switchBase: {
					margin: 2,
					padding: 0,
					transitionDuration: "300ms",
					[`&.Mui-checked`]: {
						transform: "translateX(16px)",
						[`&+.${switchClasses.track}`]: {
							opacity: 1,
						},
					},
					[`&.Mui-disabled`]: {
						[`&.${switchClasses.thumb}`]: {
							backgroundColor: "rgba(248, 255, 253, 1)",
						},
						[`&+.${switchClasses.track}`]: {
							opacity: 0.38,
						},
					},
				},
				track: ({ ownerState, theme }) => ({
					backgroundColor: theme.palette.black.a12,
					opacity: 1,
					height: 20,
					width: 36,
					borderRadius: 18,
					transition: theme.transitions.create(["background-color"], {
						duration: 500,
					}),
				}),
				thumb: ({ theme }) => ({
					height: 16,
					width: 16,
					backgroundColor: theme.palette.background.paper,
					boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontSize: theme.typography.body2.fontSize,
					"&:active": {
						boxShadow: "none",
					},
					[`& .${chipClasses.deleteIcon}`]: {
						transition: theme.transitions.create(["color"]),
					},
				}),
				outlined: ({ theme, ownerState }) => {
					return {
						backgroundColor: alpha(theme.palette[ownerState?.color ?? "primary"].main, 0.06),
						color: theme.palette.black.a54,
						[`&.${chipClasses.clickable}`]: {
							"&:hover": {
								backgroundColor: alpha(theme.palette[ownerState?.color ?? "primary"].main, 0.12),
							},
						},
						[`& .${chipClasses.deleteIcon}`]: {
							color: theme.palette.black.a54,
							transition: theme.transitions.create(["color"]),
							"&:hover": {
								color: theme.palette.black.a74,
							},
						},
					};
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: "standard",
				InputLabelProps: { shrink: true },
			},
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					[`& .${inputClasses.root}`]: {
						border: `1px solid ${theme.palette.black.a12}`,
						borderRadius: 12,
						backgroundColor: "transparent",
						transition: theme.transitions.create(["border-color", "background-color", "box-shadow", "filter"]),
						i: {
							paddingTop: 0,
						},
						"&:hover": {
							backgroundColor: theme.palette.background.default,
						},
						"&.Mui-focused": {
							borderColor: theme.palette[ownerState.color ?? "primary"].main,
							boxShadow: `${theme.palette[ownerState.color ?? "primary"].a12} 0 0 0 2px`,
						},
						[`& .${inputClasses.input}`]: {
							padding: "15px 12px",
						},
						"&::before": {
							content: "none",
						},
						"&::after": {
							content: "none",
						},
						[`&.${inputBaseClasses.adornedStart}`]: {
							paddingLeft: 12,
						},
						[`&.${inputBaseClasses.adornedEnd}`]: {
							paddingRight: 12,
						},
					},
					[`& .${inputBaseClasses.inputAdornedStart}`]: {
						marginLeft: 8,
						paddingLeft: "0px !important",
					},
					[`& .${inputBaseClasses.inputAdornedEnd}`]: {
						marginRight: 8,
						paddingRight: "0px !important",
					},
					[`& .${inputClasses.sizeSmall}`]: {
						[`& .${inputClasses.input}`]: {
							padding: "9px 12px",
						},
						[`&.${inputBaseClasses.adornedStart}`]: {
							paddingLeft: 9,
						},
						[`&.${inputBaseClasses.adornedEnd}`]: {
							paddingRight: 9,
						},
					},
					[`& .${inputLabelClasses.root}`]: {
						color: theme.palette.black.a87,
						transform: "scale(1) translateY(-6px)",
						fontSize: 14,
						"&.Mui-focused": {
							color: theme.palette.black.a87,
						},
					},
				}),
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						[`& .${selectClasses.select}`]: {
							backgroundColor: "transparent",
						},
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: ({ theme, ownerState }) => ({
					paddingRight: "36px !important",
					[`&.${inputBaseClasses.inputSizeSmall}`]: {
						paddingRight: "36px !important",
					},
				}),
				icon: {
					right: 4,
				},
			},
		},
		MuiMenu: {
			defaultProps: {
				PaperProps: {
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))",
						mt: 1.5,
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				},
				transformOrigin: { horizontal: "right", vertical: "top" },
				anchorOrigin: { horizontal: "right", vertical: "bottom" },
			},
			styleOverrides: {
				root: {
					[`& .${paperClasses.root}`]: {
						borderRadius: 12,
						boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
						marginTop: 10,
					},
				},
				list: {
					padding: 0,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					paddingTop: 10,
					paddingBottom: 10,
					paddingLeft: 8,
					paddingRight: 8,
					margin: 4,
					borderRadius: 12,
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					marginTop: 10,
					borderRadius: 0,
					boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					"&.Mui-checked": {
						"&.Mui-disabled": {
							color: theme.palette[ownerState.color ?? "primary"].main,
						},
					},
				}),
			},
		},
		MuiCheckbox: {
			defaultProps: {
				icon: <CheckboxIcon />,
				checkedIcon: <CheckboxIconChecked />,
				indeterminateIcon: <CheckboxIconIndeterminate />,
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: 48,
					boxShadow: "none",
				},
				paperFullScreen: {
					borderRadius: 0,
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					minHeight: 70,
					boxShadow: "4px -2px 16px rgba(0, 0, 0, 0.04)",
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					height: 4,
				},
				track: {
					height: 4,
					border: "unset",
				},
				rail: ({ theme }) => ({
					height: 4,
					backgroundColor: theme.palette.black.a6,
				}),
				thumb: {
					width: 16,
					height: 16,
					"&::before": {
						content: "none",
					},
					"&::after": {
						content: "none",
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: 12,
				},
				asterisk: ({ theme }) => ({
					fontWeight: 600,
					color: theme.palette.validation.main,
				}),
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: "#000000",
					borderRadius: 8,
					padding: 10,
					fontSize: 12,
				},
				arrow: {
					color: "#000000",
					"&::before": {
						borderTopLeftRadius: 4,
					},
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: 12,
					alignItems: "center",
				},
				standardWarning: ({ theme }) => ({
					backgroundColor: theme.palette.warning.a12,
					[`.${alertClasses.icon}`]: {
						color: theme.palette.warning.main,
					},
				}),
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.04)",
					borderRadius: 16,
					[`&.${paperClasses.elevation0}, &.${paperClasses.outlined}`]: {
						boxShadow: "none",
					},
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					":last-child": {
						paddingBottom: 16,
					},
				},
			},
		},
	},
});

export const V5ThemeProvider = ({ children }) => {
	return <ThemeProvider theme={v5Theme}>{children}</ThemeProvider>;
};
