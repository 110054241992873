import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { ComponentProps, createContext, FC, useCallback, useContext, useRef } from "react";
import type { ModalRoute } from "./routes/routes";

export function useModalNavigatorController() {
	const currentModals = useRef<{ modal: string | FC; props?: any }[]>([]);

	const innerPushModal = useCallback((modal: string | FC, props: any) => {
		if (currentModals.current.length > 0) {
			NiceModal.hide(currentModals.current[currentModals.current.length - 1]?.modal);
		}
		currentModals.current.push({ modal: modal, props });
	}, []);

	const pushModal = useCallback(
		<T extends ModalRoute, X extends T["result"]>(
			route: T,
			props?: Omit<ComponentProps<T["component"]>, "id">,
			options?: { shouldHidePrev?: boolean }
		) => {
			return new Promise<X>((resolve, reject) => {
				const allProps = { ...props, onSuccess: resolve, onCancel: reject };
				innerPushModal(route.id, allProps);
				NiceModal.show(route.id, allProps);
			});
		},
		[innerPushModal]
	);

	const pushModalById = useCallback(
		<PropsType extends object>(id: string, props?: PropsType) => {
			return new Promise<any>((resolve, reject) => {
				const allProps = { ...props, onSuccess: resolve, onCancel: reject };
				innerPushModal(id, allProps);
				NiceModal.show(id, allProps);
			});
		},
		[innerPushModal]
	);

	const pushModalByComponent = useCallback(
		<T extends FC<any>>(component: T, props?: Omit<ComponentProps<T>, "id">) => {
			return new Promise<any>((resolve, reject) => {
				const allProps = { ...props, onSuccess: resolve, onCancel: reject };
				innerPushModal(component, allProps);
				NiceModal.show(component, allProps);
			});
		},
		[innerPushModal]
	);

	const popModal = useCallback(() => {
		const poppedModal = currentModals.current.pop();
		if (poppedModal) {
			NiceModal.hide(poppedModal?.modal);
		}
		if (currentModals.current.length > 0) {
			NiceModal.show(
				//@ts-ignore
				currentModals.current[currentModals.current.length - 1].modal,
				currentModals.current[currentModals.current.length - 1].props
			);
		}
	}, []);

	const popTo = useCallback((index: number) => {
		if (currentModals.current.length > 0) {
			NiceModal.hide(currentModals.current[currentModals.current.length - 1].modal);
		}
		let actualIndex = index >= 0 ? index : currentModals.current.length - 1 + index;

		if (actualIndex >= currentModals.current.length) {
			return;
		}
		if (actualIndex < 0) {
			currentModals.current = [];
			return;
		}
		const modalToGoTo = currentModals.current[actualIndex];
		currentModals.current = currentModals.current.slice(0, actualIndex + 1);
		if (modalToGoTo) {
			//@ts-ignore
			NiceModal.show(modalToGoTo.modal, modalToGoTo.props);
		}
	}, []);

	const popToByRoute = useCallback(
		(route: ModalRoute) => {
			const index = currentModals.current.findIndex((modal) => modal.modal === route.id);
			popTo(index);
		},
		[popTo]
	);

	const dismissModal = useCallback(() => {
		if (currentModals.current.length > 0) {
			NiceModal.hide(currentModals.current[currentModals.current.length - 1].modal);
		}
		currentModals.current = [];
	}, []);

	return { dismissModal, pushModal, pushModalById, popModal, popTo, popToByRoute, pushModalByComponent };
}

type ModalNavigatorContextType = ReturnType<typeof useModalNavigatorController>;

const ModalNavigatorContext = createContext<ModalNavigatorContextType | undefined>(undefined);

export const ModalNavigatorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const ModalNavigatorController = useModalNavigatorController();
	return (
		<ModalNavigatorContext.Provider value={ModalNavigatorController}>
			<NiceModal.Provider>{children}</NiceModal.Provider>
		</ModalNavigatorContext.Provider>
	);
};

export function useModalNavigator() {
	const context = useContext(ModalNavigatorContext);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}

export function useModalRoute() {
	const { visible, id, keepMounted, remove, args, delayVisible } = useModal();

	return {
		visible,
		id,
		keepMounted,
		remove,
		args,
		delayVisible,
	};
}

export const ModalNavigator = {
	...NiceModal,
	register: <T extends ModalRoute>(route: T, initialProps?: Omit<React.ComponentProps<T["component"]>, "id">) => {
		//@ts-ignore
		NiceModal.register(route.id, route.component, initialProps);
	},
};
